.coming-soon_overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  /* filter: blur(4px); */
  transition: opacity 0.3s ease;
}

.coming-soon_info {
  text-align: center;
  padding: 1rem;
  max-width: 28rem;
  display: block;
  gap: 10px;
}

.coming-soon_badge {
  border-radius: 25px;
  background: white;
  color: black;
  font-weight: 600;
  padding: 0.25rem 1rem;
}

.coming-soon_title {
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem;
}

.coming-soon_description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
}
