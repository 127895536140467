/* PasswordStrengthMeter.css */

.password-strength-meter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1px;
}

.strength-scale {
  display: flex;
  width: 200px;
  justify-content: space-between;
}

.strength-level {
  width: 36px;
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 15px;
}

.strength-Weak .strength-level:nth-child(1) {
  background-color: #f44336;
}

.strength-Fair .strength-level:nth-child(-n + 2) {
  background-color: #ff9800;
}

.strength-Good .strength-level:nth-child(-n + 3) {
  background-color: #ffeb3b;
}

.strength-Strong .strength-level:nth-child(-n + 4) {
  background-color: #204943;
}

.strength-Very-Strong .strength-level {
  background-color: #4caf50; /* Orange-Green for Very Strong */
}

.strength-Excellent .strength-level {
  background-color: #00ff15; /* Light Green for Excellent */
}
