.selected-product-modal {
  width: 600px;
}

.selected-product-tabs-content {
  padding: 5px 15px;
}

.selected-product-tabs-wrapper {
  background-color: var(--white);
  z-index: 1;
}

@media screen and (max-width: 576px) {
  .responsive-modal {
    width: 100vw;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }

  .responsive-modal .modal-content {
    height: 90%;
  }

  .selected-product-tabs-content {
    flex: 10;
    max-height: 80%;
    overflow: auto;
    padding: 5px;
  }
}
