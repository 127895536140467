.ui_layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.ui_header {
  max-height: var(--nav-height);
  position: sticky;
  top: 0;
  margin-bottom: 20px;
}

.ui_footer {
  /* padding: var(--spacing-md); */
  /* height: 300px; */
}

.ui_main {
  flex: 1;
  gap: var(--spacing-sm);
  width: 100%;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  position: sticky;
  max-height: calc(98vh - var(--nav-height) + 5px);
  padding: var(--padding-screen);
  background-color: var(--bg-primary-color);
}

.ui_layout_sticky {
  position: sticky;
  overflow-y: auto;
  transition: width 0.3s ease;
}

.ui_sidebar {
  width: 350px;
  max-height: calc(98vh - var(--nav-height) - 22px);
  top: 8px;
  left: 0px;
  border-radius: 4px;
  /* box-shadow: var(--box-shadow); */
}

.ui_sidebar.ui_layout_sidebar_closed {
  position: relative;
  overflow-x: hidden;
  transform: translateX(-20px);
  opacity: 0.2;
  width: 0;
  transition: width 0.3s ease, opacity 0.2s ease;
}

@media screen and (max-width: 576px) {
  .ui_layout {
    gap: 0px;
  }

  .ui_sidebar {
    height: calc(100vh - var(--nav-height));
    position: sticky;
    width: 95vw;
    margin: 2px auto;
    /* border: 1px solid red; */
  }

  .ui_main {
    flex-direction: column;
    width: 100%;
    max-height: calc(100vh - var(--nav-height) + 35px);
    overflow: auto;
  }
}
