/* All sides padding */
.p-1 {
  padding: var(--spacing-xs);
}
.p-2 {
  padding: var(--spacing-sm);
}
.p-3 {
  padding: var(--spacing-md);
}
.p-4 {
  padding: var(--spacing-lg);
}
.p-5 {
  padding: var(--spacing-xl);
}

/* Horizontal padding (left & right) */
.px-1 {
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}
.px-2 {
  padding-left: var(--spacing-sm);
  padding-right: var(--spacing-sm);
}
.px-3 {
  padding-left: var(--spacing-md);
  padding-right: var(--spacing-md);
}
.px-4 {
  padding-left: var(--spacing-lg);
  padding-right: var(--spacing-lg);
}
.px-5 {
  padding-left: var(--spacing-xl);
  padding-right: var(--spacing-xl);
}

/* Vertical padding (top & bottom) */
.py-1 {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}
.py-2 {
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
}
.py-3 {
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-md);
}
.py-4 {
  padding-top: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
}
.py-5 {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
}

/* Individual sides */
.pt-1 {
  padding-top: var(--spacing-xs);
}
.pt-2 {
  padding-top: var(--spacing-sm);
}
.pt-3 {
  padding-top: var(--spacing-md);
}
.pt-4 {
  padding-top: var(--spacing-lg);
}
.pt-5 {
  padding-top: var(--spacing-xl);
}

.pr-1 {
  padding-right: var(--spacing-xs);
}
.pr-2 {
  padding-right: var(--spacing-sm);
}
.pr-3 {
  padding-right: var(--spacing-md);
}
.pr-4 {
  padding-right: var(--spacing-lg);
}
.pr-5 {
  padding-right: var(--spacing-xl);
}

.pb-1 {
  padding-bottom: var(--spacing-xs);
}
.pb-2 {
  padding-bottom: var(--spacing-sm);
}
.pb-3 {
  padding-bottom: var(--spacing-md);
}
.pb-4 {
  padding-bottom: var(--spacing-lg);
}
.pb-5 {
  padding-bottom: var(--spacing-xl);
}

.pl-1 {
  padding-left: var(--spacing-xs);
}
.pl-2 {
  padding-left: var(--spacing-sm);
}
.pl-3 {
  padding-left: var(--spacing-md);
}
.pl-4 {
  padding-left: var(--spacing-lg);
}
.pl-5 {
  padding-left: var(--spacing-xl);
}
