.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: rgb(38, 57, 77) 0px 25px 20px -10px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 9900;
}

.scroll-to-top.visible {
  animation: bounce ease-in-out 1.5s 550ms;
  transform: translateY(0);
  opacity: 1;
}
.scroll-to-top:hover {
  z-index: 100;
}

.scroll-to-top span {
  font-size: 18px;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@media screen and (max-width: 576px) {
  .scroll-to-top {
    bottom: 75px;
  }
}
