#image-component-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-skeleton-box-dimensions {
  height: 100%;
  width: 80%;
  margin: auto;
}

#image-component-wrapper .img-base {
  object-fit: contain;
  max-width: 100%;
}

#image-component-wrapper .img-base.img-active {
  cursor: pointer;
}

#image-component-wrapper .img-zoom {
  cursor: zoom-in;
  transition: transform ease-in 0.2s;
}

#image-component-wrapper .img-zoom-sm:active {
  box-shadow: var(--box-shadow);
  transform: scale(1.5);
}
#image-component-wrapper .img-zoom-md:active {
  box-shadow: var(--box-shadow);
  transform: scale(2);
}
#image-component-wrapper .img-zoom-lg:active {
  box-shadow: var(--box-shadow);
  transform: scale(3);
}
