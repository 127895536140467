#hp-v3-container {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-lg);
  scroll-behavior: smooth;
}

.hp-card {
  width: 80%;
  margin: auto;
  padding: var(--spacing-lg);
}

@media screen and (max-width: 576px) {
  .hp-card {
    width: 95%;
  }
}
