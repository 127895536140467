#cart-container {
  width: 100%;
  max-width: 390px;
  min-width: 250px;
  height: calc(100% - 5px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white);
}

.cart-items-wrapper {
  height: 85%;
  overflow-y: auto;
  position: relative;
}

.cart-summarize {
  /* background-color: var(--secondary-color); */
  text-align: center;
  margin: 5px 3px;
  border-top: var(--border);
  /* box-shadow: var(--box-shadow-secondary); */
  width: 100%;
}

.cart-total-box {
  /* background-color: var(--secondary-color); */
  text-align: center;
  /* color: white; */
  padding: 5px 0;
  font-weight: bolder;
  /* border-radius: 5px; */
}
