.cart-header {
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
  color: var(--white);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: var(--box-shadow);
  /* border: 1px solid red; */
  /* white-space: nowrap; */
  position: relative;
  text-align: center;
}

.cart-header-cls-btn {
  color: var(--white) !important;
}
.cart-header-cls-btn:hover {
  opacity: 0.7 !important;
}

.cart-header > :nth-child(2) {
  flex: 1;
}
