.cart-compare-single-item-view_wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  max-width: 100%;
}

.cart-compare-section-box {
  width: 100%;
  border-radius: 5px;
  /* background-color: green; */
}

.cart-compare-single-item-view_top {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  margin: 0 auto;
  gap: 10px;
  /* border: 1px solid red; */
}

.cart-compare-single-item-view_top_carousel {
  /* border: 1px solid red; */
  width: 100%;
  margin: auto;
  max-width: 600px;
}

.cart-compare-single-item-view_bottom {
  flex: 1;
  width: 100%;
  text-align: center;
  overflow: auto;
  padding-top: 10px;
  /* border: 1px solid red; */
  max-height: 30vh;
}

.cart-compare-single-item-view_bottom_otherStores_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: flex-start; */
  gap: 10px;
  height: 90%;
  overflow: auto;
  margin-top: 2px;
  max-width: 100%;
}

.cart-compare-single-item-view_bottom_otherStores_wrapper .cart_item_wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  /* max-width: 100%; */
  overflow: hidden;
  transform: scale(0.9);
  border-radius: 5px;
}

.cart-compare-single-item-view_bottom_otherStores_wrapper .cart_item_wrapper .cart-product-wrapper {
  padding: 0 20px;
}

.cart-compare-single-item-view_bottom_otherStores_wrapper .cart_item_wrapper .cart_item_store_tag_match,
.cart-compare-single-item-view_bottom_otherStores_wrapper .cart_item_wrapper .cart_item_store_tag_not_match {
  width: 100%;
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: 500;
}
.cart-compare-single-item-view_bottom_otherStores_wrapper .cart_item_wrapper .cart_item_store_tag_not_match {
  background-color: var(--secondary-color);
}

/** Special class to overwrite on current product card wrapper and his children */
.cart-compare-single-item-view_product_card_wrapper {
  max-width: unset;
  height: 230px;
}

.cart-compare-single-item-view_product_card_wrapper .item_img_card img {
  /* border: 1px solid red; */
  height: 100px;
}
.cart-compare-single-item-view_product_card_wrapper .product-amount-wrapper {
  border: 1px ridge rgba(125, 163, 125, 0.514);
  box-shadow: var(--box-shadow);
  top: 50%;
  height: 60px;
  width: 100%;
  left: 0;
  padding: 10px;
  transform: scale(0.4);
}
.cart-compare-single-item-view_product_card_wrapper .product-amount-wrapper .product-amount-wrapper_info {
  display: none;
}

@media screen and (max-width: 576px) {
  .cart-compare-section-box {
    padding: 6px;
    background-color: var(--bg-primary-color);
  }
  .cart-compare-single-item-view_product_card_wrapper {
    width: 100% !important;
    font-size: var(--font-size-small);
    padding: 0;
    height: 160px;
    min-height: auto;
    /* border: 1px solid red; */
  }

  .cart-compare-single-item-view_product_card_wrapper .item_img_card img {
    /* border: 1px solid red; */
    height: 50px;
  }

  .cart-compare-single-item-view_product_card_wrapper .item_name_card {
    font-size: var(--font-size-small);
  }

  .cart-compare-single-item-view_product_card_wrapper .product-amount-wrapper {
    top: 55%;
  }
}
