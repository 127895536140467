.progress-bar-container {
  width: 100%;
  height: 15px;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  transition: width 0.5s ease;
  border-radius: 5px;
}

.value-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
  color: var(--white);
  transition: opacity 0.5s ease;
  line-height: 1rem;
}
