.login_to_store_wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 300px;
  max-width: 600px;
  width: 95%;
  margin: auto;
  /* border: 1px solid black; */
  font-family: Arial, sans-serif !important;
}
