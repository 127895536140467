.warning-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  min-width: fit-content;
  padding: 10px;
  margin: auto;
}

.warning-modal.top-left {
  /* top: calc(var(--nav-height) + 15px); */
  top: 0px;
  left: 15px;
  animation: leftModal 0.3s ease-in-out;
}
.warning-modal.top-right {
  top: calc(var(--nav-height) + 15px);
  right: 15px;
  animation: rightModal 0.3s ease-in-out;
}

.warning-modal.top {
  left: 50%;
  top: 0px;
  /* top: calc(var(--nav-height) + 15px); */
  transform: translate(-50%);
  animation: topModal 0.3s ease-in-out;
}
.warning-modal.center {
  left: 50%;
  top: calc(50%);
  transform: translate(-50%, -50%);
  animation: topModal 0.3s ease-in-out;
}
.warning-modal.bottom {
  left: 50%;
  bottom: 15px;
  transform: translate(-50%);
  animation: bottomModal 0.3s ease-in-out;
}
.warning-modal.bottom-right {
  right: 15px;
  bottom: 15px;
  animation: rightModal 0.3s ease-in-out;
}
.warning-modal.bottom-left {
  left: 15px;
  bottom: 15px;
  animation: leftModal 0.3s ease-in-out;
}

.message-container {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  padding: 5px 10px;
  border-radius: 10px;
}
