.cart-item-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 80px;
  max-height: 100px;
  justify-content: center;
  width: 99%;
  margin: 3px auto;
  /* border: 1px springgreen solid; */
  /* border-radius: 15px; */
  max-width: 350px;
  transition: var(--transition);
  overflow-y: auto;
  /* background-color: white; */
}

.cart-item-details_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  cursor: pointer;
}

.cart-item-details_wrapper:hover {
  opacity: 0.8;
}

.cart-product-wrapper {
  /* background-color: var(--light-color); */
  background-color: var(--white);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 2%;
  font-size: calc(var(--font-size-base) - 2px);
  /* border: 1px red solid; */
  padding: 0 10px;
}

.cart-item-buttons-wrapper {
  width: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red; */
}

.cart-item-price {
  white-space: nowrap;
  direction: ltr;
  text-align: center;
}

.cart-item-img {
  max-width: 80px;
  max-height: 80px;
  overflow: hidden;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--font-color);
  flex: 0.85;
}

.cart-item-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.cart-item-amount-btn {
  cursor: pointer;
  margin: 0 10px;
}

.item-amount-unit-wrapper {
  padding: 5px;
  width: 2rem;
  height: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-color);
  background-color: var(--light-color);
  font-weight: bold;
  color: var(--primary-color);
}

/* new */
.cart-item_not_found_in_cart_img {
  position: relative;
  width: 40px;
  height: 40px;
  font-size: var(--font-size-large);
}

.cart-item_not_found_in_cart_img-large {
  font-size: 25px;
  width: 50px;
  height: 50px;
}

.cart-item_not_found_in_cart_img::after {
  content: '?';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Constant font-size not affected by accessability */
  width: 75%;
  height: 75%;
  border-radius: 50%;
  border: var(--primary-contrast-color) 3px solid;
  box-shadow: var(--box-shadow-secondary);
  /* pointer-events: none; */
}
