.input-data {
  height: 40px;
  position: relative;
}

.input-data,
.input-validator {
  margin: 0 20px;
}

.input-validator {
  min-height: 1.5rem;
  line-height: 12px;
  margin-bottom: 5px;
  color: red;
  font-size: var(--font-size-small);
}

.input-data input {
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 10%;
  border: none;
  font-size: calc(var(--font-size) + 1px);
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  color: var(--primary-color);
}

.input-data input:focus ~ label,
.input-data input:valid ~ label {
  transform: translateY(-20px);
  font-size: calc(var(--font-size) -+ 2px);
  color: #11998e;
}

.input-data label,
.input-data .input-icon {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: var(--font-size);
  transition: all 0.3s ease;
  color: #9b9b9b;
}

.input-data .input-icon {
  right: 90%;
  z-index: 100;
  pointer-events: all;
}

.input-data .underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}

.input-data .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  /* background: var(--secondary-color); */
  background-image: linear-gradient(to right, #11998e, #38ef7d);
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before {
  transform: scale(1);
}
