#contact {
  width: 100vw;
  /* height: calc(100vh - var(--nav-height)); */
  background-color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form-class {
  padding: 10px 20px;
  width: 100%;
  margin: auto;
  background-color: white;
  position: relative;
  max-width: 1000px;
  min-width: 330px;
  overflow: hidden;
}

.contact-form-class label {
  width: 20%;
  /* border: 1px solid red; */
  text-align: right;
}

#contact .titles {
  margin: 15px auto;
  /* color: white; */
}

.contact-input {
  padding: 10px;
  width: 100%;
  margin: 10px auto;
}
