.accordion-wrapper {
  /* background: #666; */
  width: 100%;
  overflow: hidden;
  padding-bottom: 4rem;
}

.accordion-item {
  font-size: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* background: #9701f0; */
  border-bottom: 1px solid rgba(63, 46, 73, 0.3);
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-item-title,
.accordion-submenu-link {
  text-decoration: none;
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  /* color: #fff; */
}

.accordion-item-submenu,
.accordion-item-submenu-open {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s;
  background: var(--bg-secondary-color);
}
.accordion-item-submenu-open {
  background: transparent;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-height: 80vh;
  overflow: auto;
}

.accordion-submenu-link {
  font-size: 16px;
  background: transparent;
  transition: background 0.2s ease-in;
}
.accordion-submenu-link:hover {
  background: #4c4b4d;
}
