.ui-single-slider-wrapper {
  direction: ltr;
  position: relative;
  width: 100%;
  touch-action: none;
  user-select: none;
}

.slider-track {
  position: relative;
  height: 5px;
  background-color: #dddddd;
  border-radius: 999px;
}

.slider-range {
  position: absolute;
  height: 100%;
  background-color: #18181b;
  border-radius: 999px;
}

.slider-thumb {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #18181b;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.slider-thumb:hover {
  outline: 1 solid #18181b;
  outline-offset: 1px;
}

.slider-thumb:focus {
  outline: 1px solid #18181b;
  outline-offset: 1px;
}

.ui-single-slider-wrapper input[type="range"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
