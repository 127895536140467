.skeleton-card {
  position: relative;
  background-color: var(--bg-primary-color);
  overflow: hidden;
}

/* Basic styles for animated shapes */
.skeleton-rect,
.skeleton-circle {
  position: absolute;
  background: linear-gradient(-90deg, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: shimmer 1.5s ease-in-out infinite;
}

/* Circle specific styles */
.skeleton-circle {
  border-radius: 50%;
}

/* Animation keyframes */
@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
