.modal-auth {
  min-width: 350px;
}

.auth-card-wrapper {
  border-radius: 12px;
  padding: 0px 20px;
  width: 100%;
  max-width: 450px;
  min-width: 200px;
  animation: fade-in-translateY 0.5s ease-out;
}

.auth-card-subtitle {
  color: var(--font-color-secondary);
  text-align: center;
  margin-bottom: 30px;
  font-size: var(--font-size-base);
}

.redirect-to {
  /* margin-top: 10px; */
  color: var(--secondary-color);
  font-size: calc(var(--font-size-base) - 2px);
  cursor: pointer;
  margin: 10px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-decoration: none;
}

.redirect-to > * {
  margin-bottom: 5px;
}

.redirect-to span:hover {
  cursor: pointer;
  opacity: 0.8;
}

.response-message {
  margin-bottom: 10px;
  display: flex;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 5px;
  border-radius: 4px;
}

.auth-card-wrapper_register {
  width: 450px;
  padding: 1px 35px;
}

/* Responsive Design */
@media (max-width: 576px) {
  .modal-auth-register {
    min-width: 100vw;
    border-radius: 0;
    height: 100vh !important;
    overflow: auto;
  }
  .auth-card-wrapper_register {
    width: fit-content;
    width: 95%;
    max-width: 95vw;
    margin: auto;
    box-sizing: border-box;
    padding: 0 10px;
    /* min-width: ; */
  }

  .response-message {
    font-size: var(--font-size-small);
  }
}

#phoneNumber.form__input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
  -webkit-appearance: none; /* For Chrome, Safari, Edge, Opera */
  appearance: none; /* Standard property for broader compatibility */
}

#phoneNumber.form__input[type="number"]::-webkit-inner-spin-button,
.form__input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* For Chrome, Safari, Edge, Opera */
  margin: 0; /* Prevent layout issues */
}
