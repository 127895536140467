.hamburger-button {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: 15px;
  margin-left: 5px;
  /* justify-content: center; */
}

.bar {
  width: 25px;
  height: 3px;
  background-color: var(--white);
  margin: 3px 0;
  transition: 0.4s;
}

.active .bar:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.active .bar:nth-child(2) {
  opacity: 0;
}

.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* .hamburger-button:hover .bar {
  background-color: var(--primary-contrast-color);
} */

.active:hover .bar {
  background-color: var(--secondary-color);
}
