/* DynamicSelect.css */

.dynamic-select {
  position: relative;
  font-size: var(--font-size-base);
}

.dynamic-select .ssui-label-inline {
  position: absolute;
  top: -10px;
  background-color: var(--white);
  right: 15px;
  padding: 1px 5px;
  color: var(--primary-dark);
  font-size: 12px;
}

.dynamic-select .ssui-label-top {
  display: block;
  margin-bottom: 5px;
  font-size: calc(var(--font-size-base) - 2px);
  font-weight: 600;
  color: var(--font-color);
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px;
  border: 1px solid #ccc;
  background-color: var(--white);
  cursor: text;
  border-radius: 5px;
}

.selected-option-tag {
  display: flex;
  padding: 4px 10px;
  color: var(--font-color);
  margin: 4px;
  background-color: #e2e2e2;
  border-radius: 5px;
  cursor: default;
  position: relative;
}

.selected-options-open-tag {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0;
}

.selected-option-tag h6 {
  width: 100%;
  padding: 0px 3px;
}

.selected-option:hover {
  background-color: #cecaca;
}

.remove-option {
  /* margin-left: 8px; */
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 8px;
  position: absolute;
  top: 0px;
  left: 3px;
}

.search-input {
  flex: 1;
  margin: 2px;
  padding: 4px 10px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--font-color);
}

.search-input-disabled,
.search-input-disabled .search-input {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.option-list {
  /* position: absolue/sticky; // set as inline style in props*/
  top: 100%;
  width: 100%;
  left: 0px;
  z-index: 10000;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--white);
  border: 1px solid #ccc;
  border-top: transparent;

  border-radius: 5px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow-y: auto;
  padding: 8px 10px;
}

.option {
  display: block;
  padding: 6px 8px;
  margin: 2px 0;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  width: 100%;
  border-radius: 5px;
  transition: var(--transition);
}

.option:hover {
  background-color: var(--bg-secondary-color);
}
.option:active {
  background-color: var(--bg-primary-color);
}

.selected {
  background-color: var(--bg-primary-color);
  font-weight: bold;
}

.selected .checkmark {
  margin-left: 4px;
  /* color: var(--success); */
}

.no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: #999;
}

.ssui-icon {
  position: absolute;
  left: 2%;
  width: 14px;
  height: 14px;
}

.ssui-remove-all {
  font-size: 8px;
  line-height: 14px;
  border-radius: 50%;
  background-color: var(--bg-secondary-color);
  color: var(--black);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ssui-remove-all:hover {
  transition: var(--transition);
  color: var(--white);
  background-color: var(--dark-color);
  font-size: 0.4rem;
}
