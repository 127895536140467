/* Typography.css */

.typography {
  margin: 0;
  color: var(--font-color);
}

.typography-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.typography-h1 {
  font-size: calc(var(--font-size-base) + 18px);
  font-weight: bold;
}

.typography-h2 {
  font-size: calc(var(--font-size-base) + 10px);
  font-weight: bold;
}

.typography-h3 {
  font-size: calc(var(--font-size-base) + 8px);
  font-weight: bold;
}

.typography-h4 {
  font-size: calc(var(--font-size-base) + 6px);
  font-weight: bold;
}

.typography-h5 {
  font-size: calc(var(--font-size-base) + 4px);
  font-weight: bold;
}

.typography-h6 {
  font-size: var(--font-size-base);
  font-weight: bold;
}

.typography-p {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  margin-bottom: var(--spacing-md);
}

.typography-text {
  font-size: var(--font-size-base);
}

.typography-paragraph {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  margin-bottom: var(--spacing-md);
}

.typography-secondary {
  color: var(--font-color-secondary);
}
