.reset-password-container {
  width: 350px;
  height: 420px;
  margin: 10% auto;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  overflow: auto;
}

.reset-password-container form {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.reset-password-container h2 {
  margin: 10% auto;
}

.reset-password-container form > * {
  margin-bottom: 20px;
}

.res-message-reset-password {
  width: 90%;
  margin: auto;
}
