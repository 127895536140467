.select-search-container {
  position: relative;
  width: 100%;
  /* flex: 1; */
}
.select-search-wrapper {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}
.select-search-wrapper-focus {
  box-shadow: 0 0 0 0.2rem var(--light-color);
}

.select-search-wrapper-focus-open {
  border-radius: 8px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.select-search-input,
.select-search-btn {
  flex: 1;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid transparent;
  /* border-radius: 0px; */
  font-size: 1rem;
  line-height: 1.5;
  appearance: none;
  background-color: var(--light-color);
  color: var(--font-color);
}

.select-search-btn.filters-bg-icon {
  background-color: var(--white);
}

.select-search-input-with-btn {
  border-right: transparent;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.select-search-btn {
  cursor: pointer;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  max-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  border-right: transparent;
  flex: 0.1;
}

.select-search-input:focus {
  outline: none;
}

.select-result-div {
  width: calc(100% - 2px);
  box-shadow: var(--box-shadow);
  position: absolute;
  top: 101%;
  z-index: 1000;
  background-color: var(--white);
  /* border: var(--border); */
  max-height: 400px;
  overflow: auto;
  color: var(--font-color);
  padding-bottom: 1px;
  border-radius: 0 0 8px 8px;
}

@media screen and (max-width: 500px) {
  .select-result-div {
    width: fit-content;
    max-width: 90vw;
    margin: auto;

    min-width: 100%;

    left: 50%;
    transform: translate(-50%);
  }
}

.result-option-wrapper {
  margin: 1px 2px;
  padding: 5px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

.result-option-wrapper:hover {
  background-color: var(--light);
  cursor: pointer;
}
