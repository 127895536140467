.card {
  /* border: var(--border); */
  overflow: auto;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: var(--white);
  position: relative;
  display: flex;
  flex-direction: column;
}

.card-shadow {
  box-shadow: var(--box-shadow);
}

.card-radius {
  border-radius: 8px;
}

.card-shadow-hover:hover {
  cursor: pointer;
  box-shadow: var(--box-shadow-secondary);
}

.card-image {
  width: 100%;
  height: auto;
}

.card-content {
  padding: var(--spacing-md);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card-title {
  font-size: var(--font-size-large);
  padding: 10px 10px 0px;
  /* border: 1px solid red; */
  width: 100%;
  color: var(--font-color);
}

.card-subtitle {
  /* text-align: center; */
  font-size: calc(var(--font-size-base) + 2px);
  padding: 5px 10px;
  color: var(--font-color-secondary);
}

.card-body {
  font-size: var(--font-size-base);
  color: var(--font-color);
  flex: 1;
}

.card-footer {
  /* padding: 16px; */
  border-top: 1px solid #e0e0e0;
  background-color: #f9f9f9;
  text-align: right;
}

.card-icon {
  position: absolute;
  top: 3%;
  font-size: var(--font-size-base);
  color: #333;
}

.card-icon-right {
  right: 3%;
}
.card-icon-left {
  left: 3%;
}

.card-header {
  display: flex;
}

@media screen and (max-width: 576px) {
  .card-content {
    padding: var(--spacing-sm);
  }
}

.card-variant-blue {
  background-color: #f2f7ff;
  border: 1px solid #d0e3ff;
}
