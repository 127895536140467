.sorting-cart-categories-header-wrapper {
  background-color: var(--bg-primary-color);
  padding: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sorting-cart-categories-header-wrapper .sorting-cart-title {
  flex: 1;
  gap: 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
