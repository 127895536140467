.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  /* width: 100%; */
  /* border: 1px solid red; */
}

.spinner-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: rgba(49, 49, 49, 0.2);
  width: 100%;
  height: 100%;
}

.spinner {
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
