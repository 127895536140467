.animated-component {
  transition: opacity 0.5s ease-out;
}

/* Base animation classes */
.animation-fade-in {
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: both; /* Ensures the element retains the end state of the animation */
}

/* Fade-in with translateY */
.animation-fade-in-translateY {
  animation-name: fade-in-translateY;
}

@keyframes fade-in-translateY {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade-in with translateX */
.animation-fade-in-translateX {
  animation-name: fade-in-translateX;
}

@keyframes fade-in-translateX {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Fade-in with translateX reverse */
.animation-fade-in-translateX-reverse {
  animation-name: fade-in-translateX-reverse;
}

@keyframes fade-in-translateX-reverse {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Staggered animation classes */
.staggered-animation {
  animation-fill-mode: both;
}

.staggered-animation-1 {
  animation-delay: 0.1s;
}

.staggered-animation-2 {
  animation-delay: 0.2s;
}

.staggered-animation-3 {
  animation-delay: 0.3s;
}

.staggered-animation-4 {
  animation-delay: 0.4s;
}

.staggered-animation-5 {
  animation-delay: 0.5s;
}

@keyframes fade-in-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animation-fade-in-opacity {
  animation-name: fade-in-opacity;
}
