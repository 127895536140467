.cart_comparison_custom_view_container {
  display: flex;
  gap: 30px;
  width: 100%;
  /* max-width: 1200px; */
  height: 600px;
  margin: auto;

  position: relative;
  overflow-x: auto;
}

.small-cart-item {
  width: 330px !important;
  min-width: 250px;
  max-width: none;
  border-left: var(--border);
  border-right: var(--border);
  flex-wrap: nowrap;
}
.big-cart-item {
  border-left: var(--border);
  flex-wrap: nowrap;
  border-right: var(--border);
  width: 400px !important;
  max-width: none;
  min-width: 300px;
}

.pseudo-cart-header-wrapper {
  position: relative;
}
.pseudo-cart-header-wrapper::after {
  content: '';
  width: 100%;
  height: 450px;
  box-shadow: var(--box-shadow);
  position: absolute;
  pointer-events: none;
  border-radius: 4px;
}

.cart-comparison_cart_footer {
  padding: 5px 10px;
  font-weight: bold;
  box-shadow: var(--box-shadow);
  border-top: 2px double var(--border-color);
  border-bottom: 2px double var(--border-color);
  background-color: var(--white);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  /* margin-bottom: 1px; */
  /* bottom: 0px; */
}

.cart-comparison_cart_footer_box {
  padding: 10px;
  font-size: calc(2px + var(--font-size-small));
  height: 30px;
  /* border: 1px solid red; */
  /* max-width: 250px; */
  /* margin: auto; */
}

.cart-comparison_cart_footer_box > :nth-child(2) {
  flex: 1;
  text-align: right;
}

@media screen and (max-width: 576px) {
  .big-cart-item {
    width: 350px !important;
  }
  .small-cart-item {
    width: 350px !important;
  }
  .cart-comparison_cart_footer_box {
    font-size: var(--font-size-small);
  }
}
