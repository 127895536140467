.ui-tabs-wrapper {
  width: 100%;
}
.ui-tabs-border-horizontal {
  position: relative;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.ui-tabs-border-vertical {
  position: relative;
  margin-bottom: 1rem;
  border-left: 1px solid #e0e0e0;
}

.ui-tab {
  padding: 1rem;
  cursor: pointer;
  position: relative;
  color: var(--font-color-secondary);
  transition: color 0.3s ease;
}

.ui-tab-disabled {
  cursor: no-drop;
  opacity: 0.8;
  position: relative;
}

.ui-tab-disabled-message {
  display: none;
}

.ui-tab-disabled:hover .ui-tab-disabled-message {
  transition: all ease-in 0.5s;
  display: block;
  right: 0;
  position: absolute;
  opacity: 1;
  color: var(--font-color-secondary);
  z-index: 100;
  bottom: 80%;
  text-align: center;
  font-size: var(--font-size-small);
  font-style: italic;
}

.ui-tab::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #2196f3;
  transition: width 0.3s ease, left 0.3s ease;
}

.ui-tab.ui-tab-active::after {
  width: 100%;
}

.ui-tab.ui-tab-active {
  color: #2196f3;
}
