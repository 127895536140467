@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100..900&display=swap");

@font-face {
  font-family: "Open-Sans-Cap";
  src: url("./assets/fonts/OpenSansHebCapital.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-family: "Noto Sans Hebrew", serif;
}

ul {
  list-style: none;
}

a {
  color: inherit; /* or set it to your desired color, e.g., #000000 for black */
  text-decoration: none;
}

::-webkit-scrollbar {
  display: none;
}

.scrollable::-webkit-scrollbar {
  display: initial;
  /* width: 8px; */
  /* height: 10px; */
  width: 15px;
}

.scrollable::-webkit-scrollbar-track {
  /* background: transparent; */
  /* overflow: hidden; */
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

.scrollable::-webkit-scrollbar-thumb {
  /* border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7); */
  background-color: #d6dee1;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.weak {
  color: #a7a6a6b0;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

/* responsive */
@media screen and (min-width: 576px) {
  .desktop-display-none {
    display: none !important;
  }
}
@media screen and (max-width: 576px) {
  #main-container {
    flex-direction: column;
    margin-top: 0;
  }

  .responsive-transparent {
    opacity: 0 !important;
  }
  .responsive-hidden {
    display: none !important;
  }

  .responsive-flex-3 {
    flex: 3 !important;
  }

  .responsive-marginRL-10 {
    margin: 0 5px !important;
  }

  .responsive-flex-column {
    display: flex;
    flex-direction: column;
  }
  .responsive-flex-row {
    display: flex;
  }
}

@media screen and (max-width: 800px) {
  .responsive-hidden-lg {
    display: none !important;
  }
}

/* common style */
.container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-align {
  display: flex;
  align-items: center;
}

.flex-gap-10 {
  gap: 10px;
}
.flex-gap-20 {
  gap: 20px;
}

.flex-space-around {
  justify-content: space-around;
}

.transparent {
  color: transparent;
}

.primary-color {
  color: var(--primary-color);
}
.primary-contrast-color {
  color: var(--primary-contrast-color);
}

.space-height-sm {
  height: var(--spacing-sm);
}
.space-height-md {
  height: var(--spacing-md);
}
.space-height-lg {
  height: var(--spacing-lg);
}
.space-height-xl {
  height: var(--spacing-xl);
}

.space-height-xxl {
  height: var(--spacing-xxl);
}
