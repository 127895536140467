.ui-radio-label input[type="radio"] {
  --s: 1em; /* control the size */
  --c: #009688; /* the active color */

  height: var(--s);
  aspect-ratio: 1;
  border: calc(var(--s) / 8) solid #939393;
  padding: calc(var(--s) / 8);
  background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0 no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--s) / 10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: 0.3s;
}

.ui-radio-label input[type="radio"]:checked {
  border-color: var(--c);
  background-size: 100% 100%;
}

.ui-radio-label input[type="radio"]:disabled {
  background: linear-gradient(#939393 0 0) 50%/100% 20% no-repeat content-box;
  opacity: 0.5;
  cursor: not-allowed;
}

@media print {
  .ui-radio-label input[type="radio"] {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
    background: none;
  }
}

.ui-radio-label {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0;
  cursor: pointer;
  font-size: 16px; /* Added font-size for the label */
}
