.collapse-ui-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.collapse-ui-single-wrapper {
  /* border: 1px solid red; */
}

.collapse-ui-single-title {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  border-bottom: var(--border);
  border-width: 2px;
  cursor: pointer;
  color: var(--font-color-secondary);
  align-items: center;
}

.collapse-ui-single-title span {
  font-size: 20px;
  transition: transform 0.3s;
}

.collapse-ui-single-icon-open {
  transform: rotate(-90deg);
}

.collapse-ui-single-content-closed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
  margin-top: 15px;
}

.collapse-ui-single-content-open {
  max-height: 50vh;
  overflow: auto;
  transition: max-height 0.5s;
  margin-top: 15px;
}
