.responsive_menu {
  direction: ltr;
  /* background-color: #fff; */
  width: 100%;
  margin: auto;
  position: fixed;
  bottom: 0%;
  /* padding: 0 5px; */
  /* border-bottom-left-radius: 25px; */
  /* border-bottom-right-radius: 25px; */
}

.responsive_menu ul {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--primary-color);
}

.responsive_menu ul li {
  flex: 1;
  height: 60px;
  width: 20%;
  /* font-size is for the icons and is not affected by accessability*/
  font-size: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  /* border: 1px solid red; */
}

#menu_circle {
  position: absolute;
  width: 70px;
  height: 70px;
  background-image: linear-gradient(var(--primary-color), var(--secondary-color), var(--primary-color));
  border-radius: 50%;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border: 6px solid var(--bg-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: left 0.8s ease;
}

#menu_circle li > * {
  animation: moveup 0.1s linear forwards;
}

.remove_icon {
  opacity: 0;
  position: relative;
  transition: opacity 0.1s ease;
}

@keyframes moveup {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
