.store-selection {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
}

.store-selection_header {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}

.store-selection_summary-table {
  width: 100%;
  border-collapse: collapse;
}

.store-selection_summary-table th,
.store-selection_summary-table td {
  padding: 10px;
  border: 1px solid #ccc;
  /* text-align: left; */
}

.store-selection_summary-table th {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
}

.store-selection_store-grid {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  gap: 15px;
  padding-bottom: 10px;
  max-height: 550px;
  overflow: auto;
}

@media screen and (max-width: 576px) {
  .store-selection_store-grid {
    justify-content: center;
  }
}

.store-selection_store-item {
  position: relative;
  padding: 15px 5px;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  text-align: center;
  background: var(--white);
  width: 255px;
  opacity: 0.7;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  height: 160px;
}

.store-selection_store-label {
  opacity: 0;
  transition: var(--transition);
}

.store-selection_store-item:hover .store-selection_store-label {
  opacity: 1;
}

.store-selection_store-item:hover {
  opacity: 1;
}

.store-selection_store-item:active {
  scale: 0.95;
}

.store-selection_store-item.store-preference_active,
.store-selection_store-item.active-favorite {
  opacity: 1;
  border: 2px solid #62ff07a1;
  background-color: rgba(32, 252, 43, 0.1);
}

.store-selection_store-checkbox {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.store-selection_store-actions {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  
  /* flex-direction: column; */
  gap: 3px;
}

.store-selection_btn {
  display: inline-block;
  margin-top: 5px;
  padding: 8px 12px;
  border: none;
  width: 48%;
  margin: auto;
  border-radius: 4px;
  cursor: pointer;
  line-height: 1rem;
}

.favorite-btn.store-preference_active {
  background-color: var(--primary-contrast-color);
  color: var(--white);
}

.store-selection_club-card-btn.store-preference_active {
  background-color: var(--secondary-color);
  color: var(--primary-contrast-color);
}

.store-selection_club-card-status.green {
  color: green;
}

.store-selection_club-card-status.red {
  color: red;
}





.warning-modal-content p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.warning-modal-content p span {
  font-weight: bold;
  color: #d32f2f;
}