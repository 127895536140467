.tutorial-body-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
}

.tutorial-img-wrapper {
  max-width: 40%;
  cursor: zoom-in;
}

.tutorial-img {
  max-width: 100%;
  max-height: 350px;
  object-fit: contain;
  transition: all 0.3s ease;
}

.tutorial-img.enlarged {
  max-height: 700px;
  position: absolute;
  left: 0%;
  top: 0%;
  /* transform: translate(-50%, -50%); */
  background-color: var(--white);
  z-index: 10;
}

.tutorial-img-wrapper.enlarged {
  max-width: 70%;
  position: relative;
  width: 100vw;
  height: 100%;
  cursor: zoom-out;
}

.tutorial-body-left,
.tutorial-body-right {
  flex: 1;
  text-align: justify;
}

.tutorial-body-right {
  /* direction: ltr; */
  /* padding-right: 40px; */
  /* text-align: left; */
}

.tutorial-card {
  margin: 50px 15px;
  padding: 10px 5px;
  transition: var(--transition);
  cursor: pointer;
}

.tutorial-card:hover {
  background-color: var(--primary-color);
  border-radius: 7px;
  color: var(--white);
}

.tutorial-card:active {
  opacity: 0.5;
  background-color: var(--secondary-color);
}

@media screen and (max-width: 576px) {
  .tutorial-body-wrapper {
    flex-direction: column;
  }

  .tutorial-img-wrapper {
    width: 100%;
  }
}
