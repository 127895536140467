.button-in-group {
  box-shadow: var(--box-shadow-secondary);
  padding: 10px 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease;
  flex: 1 1 auto;
  cursor: pointer;
}

.button-in-group:hover {
  flex: 2;
}

.button-children-toggle {
  opacity: 0;
  visibility: hidden;
  max-width: 0;
  margin: 0;
  overflow: hidden;
  font-size: 0;
  transition: all 0.6s ease;
}

.button-in-group:hover .button-children-toggle {
  opacity: 1;
  font-size: auto;
  visibility: visible;
  max-width: none; /* Ensure content fits */
  margin: auto 5px; /* You can adjust this based on your design */
}
