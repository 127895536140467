#search_filter_container {
  width: 100%;
  margin: 3px auto;
  color: var(--primary-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

#search_filter_container .filters_categories_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 8px;
  flex-wrap: wrap;
  width: 98%;
  margin: auto;
  /* border: 1px solid black; */
}

#search_filter_container .collapse_btn {
  /* border: 1px solid black; */
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  /* margin-left: 5%; */
}

#search_filter_container .category_filter {
  padding: 5px 8px;
  background-color: #edeff4;
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
  max-width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

@media screen and (max-width: 860px) {
  #search_filter_container .filters_categories_wrapper .category_filter {
    max-width: 30%;
    padding: 5px 3px;
  }
  #search_filter_container .filters_categories_wrapper .category_filter:last-child {
    display: none;
  }
}

#search_filter_container .category_filter:hover {
  background-color: #d3d9e7;
}

.active_category {
  color: #edeff4;
  background-color: var(--primary) !important;
  cursor: unset !important;
}

#search_filter_container_select {
  width: 95%;
  margin: 20px auto;
  min-height: 50px;
  display: flex;
  align-items: center;
  gap: 5%;
}
