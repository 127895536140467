.cart-toggle-primary-wrapper {
  background-color: var(--primary-color);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  cursor: pointer;
  transition: var(--transition);
}

.cart-toggle-primary-wrapper:hover {
  opacity: 0.8;
}

.cart-toggle-primary-float {
  position: absolute;
  left: -12px;
  top: -8px;
  border-radius: 50%;
  background-color: var(--primary-contrast-color);
  padding: 5px;
  height: 24px;
  width: 24px;
  font-size: var(--font-size-small);
}
