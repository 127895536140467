/* Dropdown.css */

.dropdown {
  position: relative;
  display: inline-block;
  /* padding-bottom: 4px; */
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  display: none;
  z-index: 1000;
  box-shadow: var(--box-shadow-secondary);
  border-radius: 10px;
  padding: var(--spacing-sm);
  animation: dd-pops ease-in-out 0.2s;
  width: max-content;
}

@keyframes dd-pops {
  0% {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.dropdown-menu.primary {
  background-color: var(--white);
  color: var(--font-color);
}

.theme-dark .dropdown-menu.primary {
  background-color: var(--bg-primary-color);
}

.dropdown-menu.secondary {
  background-color: var(--black);
  color: var(--white);
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  padding: var(--spacing-sm);
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.dropdown-item.primary:hover {
  background-color: var(--bg-secondary-color);
}

.dropdown-item.primary:active {
  background-color: var(--bg-primary-color);
}

.dropdown-item.secondary:hover {
  background-color: var(--dark-color);
}

.dropdown-item.secondary:active {
  background-color: var(--bg-primary-color);
}
