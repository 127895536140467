.search_product_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 5px auto; */
}

.search_product_wrapper:focus-within .select-search-wrapper {
  transition: all 0.3s ease;
  border: 1px solid var(--primary-contrast-color);
  box-shadow: 0 0 0 2px rgba(255, 193, 99, 0.2);
  border-radius: 5px;
}

.nav-search-input-wrapper {
  flex: 1;
  height: 100%;
}

.sound-search {
  width: 30px;
  background-color: var(--white);
  height: 100%;
  margin: 2% 0;
}

.main-search-results {
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr 0.25fr;
  align-items: center;
  flex: 1;
  /* min-width: 250px; */
  border-bottom: 1px solid var(--border-color);
}

.main-search-price-range {
  flex: 1;
  width: 90px;
}
