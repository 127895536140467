.button {
  border: none;
  font-weight: bold;
  cursor: pointer;
  margin: auto;
  text-align: center;
  color: var(--font-color);
  border-radius: 5px;
  transition: var(--transition);
  /* position: relative; */
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.small {
  padding: 6px 12px;
  font-size: var(--font-size-small);
}

.medium {
  padding: 10px 20px;
  font-size: var(--font-size-base);
}

.large {
  padding: 14px 28px;
  font-size: var(--font-size-large);
}

/*region Btn Variants */
.btn-primary,
.btn-success,
.btn-info,
.btn-danger,
.btn-dark {
  background-color: var(--secondary-color);
  color: white;
}

.btn-info {
  background-color: #17a2b8;
}
.btn-warning {
  background-color: #ffc107;
}

.btn-danger {
  background-color: #d32f2f;
}

.btn-success {
  background-color: #28a745;
}

.btn-dark {
  background-color: #6c757d;
}

.btn-contrast {
  background-color: var(--primary-contrast-color);
  color: var(--primary-color);
}

.btn-secondary {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-bgs {
  background-color: var(--bg-secondary-color);
}

/*endrefion */

.button:hover {
  opacity: 0.85;
}
.button:active {
  opacity: 0.7;
}

.btn-dashed,
.btn-default {
  border: 1px solid rgba(168, 166, 166, 0.658);
  color: var(--font-color);
  background-color: transparent;
}

.btn-dashed {
  border-style: dashed;
}

.btn-default:hover,
.btn-dashed:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
