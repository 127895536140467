.product-main-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  overflow: auto;
  flex: 1;
  height: calc(100vh - var(--nav-height));
  max-height: min-content;
}

.products-main_data_not_found {
  text-align: center;
  height: calc(90vh - var(--nav-height));
  margin: auto;
}

@media screen and (max-width: 576px) {
  .product-main-container {
    margin: 2% auto;
  }
}
