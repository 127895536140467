.ui-drawer {
  transition: transform 0.3s ease;
  /* background: white; */
  z-index: 9999;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: var(--box-shadow-secondary);
  display: flex;
  flex-direction: column;
}

.ui-drawer-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.ui-drawer-mask-absolute {
  position: absolute;
}

.ui-drawer-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: var(--bg-secondary-color);
  border-bottom: 1px solid var(--border-color);
}

.ui-drawer-close-btn {
  background: none;
  border: none;
  font-size: var(--font-size-base);
  cursor: pointer;
}

.ui-drawer-body {
  background-color: var(--white);
  padding: 8px;
  flex: 1;
  overflow: auto;
}

.ui-drawer-footer {
  padding: 16px;
  /* background: #f0f0f0; */
  background: var(--bg-secondary-color);
  border-top: 1px solid var(--border-color);
}
