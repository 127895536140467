#product_matching_container {
  width: 99%;
  direction: rtl;
  margin: auto;
}

#product_matching_container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.product_matching_grid_container {
  width: 100%;
}

.product_matching-store_header {
  top: -3px;
  z-index: 100;
  box-shadow: var(--box-shadow);
  padding: 6px 3px;
  flex: 1 0 auto;
  background-color: var(--bg-primary-color);
  text-align: center;
  position: sticky;
  max-height: 50px;
  min-height: 50px;
  min-width: 250px;
}

/*#region admin selected modal  */
.admin-selected-source-card .card-title {
  padding: 0;
}

/*#endregion admin selected modal  */
