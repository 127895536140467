#error-page {
  width: 100vw;
  height: calc(98vh - var(--nav-height));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  background-color: var(--secondary-color);
  position: relative; /* Add relative positioning */
}

.error-content {
  /* background-color: var(--shiny-color); */
  background-color: white;
  /* box-shadow: var(--box-shadow); */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10%;
  width: 100%;
  max-width: 1000px;
  min-width: 330px;
  height: 90%;
  margin: auto;
  position: relative;
  text-align: center;
}

.error-logo {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: 10px;
}

.error-status {
  font-size: 120px;
  color: var(--primary-color);
  font-weight: bold;
  position: relative; /* Add relative positioning */
  -webkit-background-clip: text; /* Set the background-clip for WebKit/Blink browsers */
  background-clip: text; /* Set the background-clip for modern browsers */
  /* color: transparent; Make the text transparent */
  line-height: 0.8;
}

.error-message {
  font-weight: bold;
  font-size: var(--font-size-xl);
}

.error-message_original {
  padding: 20px;
  box-shadow: var(--box-shadow);
  background-color: #fcebeb;
  display: flex;
  direction: ltr;
  gap: 10px;
}

.buttons-ep {
  display: flex;
  gap: 10%;
  width: 50%;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .error-status {
    font-size: 120px;
  }

  .error-logo {
    left: 1px;
    top: 1px;
  }

  .error-content,
  .contact-form-class {
    height: 90%;
    width: 95%;
    padding: 10px;
  }
  .contact-form-class {
    height: 80%;
  }
}
