.profile-container {
  width: 100%;
  height: 80vh;
  /* height: calc(98vh - var(--nav-height)); */
  display: flex;
  /* gap: 2px; */
  box-shadow: var(--box-shadow);
  margin: 10px auto;
  border-radius: 15px;
  overflow: auto;
  position: relative;
}

.profile-container .profile-tab-content-tabs {
  background-color: #f3f4f6;
  /* background-color: red; */
  min-width: 200px;
  resize: horizontal;

  width: 30%;
  max-width: 350px;
  overflow: auto;
  height: 100%;
}

.profile-tab {
  width: 100%;
  margin: 3px auto;
  /* border: 1px solid red; */
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: var(--transition);
}

.profile-tab:hover {
  background-color: var(--white);
}

.profile-tab.active {
  background-color: var(--white);
  font-weight: bold;
}

.profile-setting-card-aside {
  width: 50%;
  height: 500px;
}

@media screen and (max-width: 576px) {
  .tabs-wrapper {
    position: sticky;
    top: 0;
  }
  .profile-setting-card-aside {
    width: 100%;
    min-height: 350px;
    height: fit-content;
  }
}
