.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.modal {
  position: relative;
  min-width: 100px;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  animation-name: zoom;
  animation-duration: 0.4s;
  margin: 1rem;
  overflow: hidden;
}

.modal-overlay .top {
  top: 20px;
}
.modal-overlay .modal.bottom {
  bottom: 20px;
}
.modal.right {
  position: absolute;
  right: 10px;
  animation: rightModal 0.5s ease-in-out;
}
.modal.left {
  position: absolute;
  left: 10px;
  animation: leftModal 0.5s ease-in-out;
}

@keyframes rightModal {
  from {
    right: -100%;
  }
  to {
    right: 10px;
  }
}
@keyframes leftModal {
  from {
    left: -100%;
  }
  to {
    left: 10px;
  }
}
@keyframes topModal {
  from {
    top: -100%;
  }
  to {
    top: 10px;
  }
}
@keyframes bottomModal {
  from {
    bottom: -100%;
  }
  to {
    bottom: 10px;
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.modal-header {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 15px;
  font-size: var(--font-size-xl);
  font-weight: bold;
  color: var(--font-color);
  flex: 1;
}

.modal-header.modal-header-normal div {
  text-align: center;
  flex: 1;
}
.modal-header.modal-header-warning {
  background: #ffc107;
  color: var(--white);
  padding: 14px;
}

.modal-tabs {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

.modal-tab {
  padding: 1rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.modal-tab.active {
  border-bottom-color: #2196f3;
  color: #2196f3;
}

.modal-content {
  margin: auto;
  padding: 4px 12px;
}

.modal-footer {
  display: flex;
  justify-content: space-around;
  padding: 12px 24px;
  gap: 1%;
}

.modal-footer button {
  margin-left: 8px;
}

@media (max-width: 576px) {
  .modal {
    width: 100vw;
    max-height: 100vh;
  }

  .margin-modal {
    margin: auto;
    border-radius: 0;
  }

  .modal-content {
    overflow-y: auto;
  }

  .modal-header {
    padding: 10px;
  }
  .modal-header h2 {
    font-size: var(--font-size-large);
  }
}
