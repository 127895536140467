/* ShoppingList.css */
#shopping-list-container {
  height: 700px;
  max-height: 60vh;
  background: linear-gradient(to bottom, white 29px, #00b0d7 1px);
  margin: auto;
  background-size: 100% 30px;
  position: relative;
  padding: 30px;
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2), 0px 0px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2), 0px 0px 6px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2), 0px 0px 6px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2), 0px 0px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2), 0px 0px 6px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

#shopping-list-content {
  font-family: "Open-Sans-Cap", sans-serif !important; /* שימוש בפונט כתב גדול */
  line-height: 30px;
}

#shopping-list-content * {
  font-size: calc(var(--font-size-large) - 2px);
  color: #0033cc;
}

#shopping-list-content input {
  min-width: 300px;
  width: 40vw;
  max-width: 400px;
  text-wrap: wrap;
  padding: 3px;
  border: none;
  outline: none;
  font-family: "Open-Sans-Cap", sans-serif !important; /* שימוש בפונט כתב גדול */
  /* font-weight: lighter; */
  background-color: transparent;
}

#shopping-list-content .pseudo-placeHolder {
  font-weight: 600;
}

#shopping-list-container:hover .pseudo-placeHolder {
  display: none;
}

#shopping-list-content input:focus {
  box-shadow: none;
}

.hipsum p {
  display: flex;
  align-items: center;
}

.sl-title-wrapper {
  /* border: 1px solid red; */
  padding: 5px 10px;
  width: 100%;
  text-align: center;
  position: relative;
}

.sl-title {
  width: 100%;
  text-align: center;
  color: var(--font-color);
}

.sl-clean-all {
  position: absolute;
  top: 0;
  left: 0;
}
