/* General Button Styles */
.ui-close-btn {
  font-family: Arial, sans-serif;
  background: none;
  border: 1px solid transparent;
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers text horizontally */
  align-items: center; /* Centers text vertically */
  color: var(--font-color-secondary);
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth hover effect */
}

.ui-close-btn:hover {
  color: var(--font-color);
}

.ui-close-btn:active {
  font-weight: bolder;
}

.ui-close-btn.ui-close-btn-bold:active {
  font-weight: lighter !important;
}

/* Small Circle Button */
.ui-close-btn.circle-start,
.ui-close-btn.circle-hover {
  border-radius: 50%; /* Makes it circular */
}

.ui-close-btn.circle-hover:hover,
.ui-close-btn.rect-start,
.ui-close-btn.circle-start {
  box-shadow: var(--box-shadow);
  background-color: var(--bg-secondary-color);
}
