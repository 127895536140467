.divider {
  display: flex;
  align-items: center;
  margin: 0 0 16px 0;
  /* margin: 0; */
}

.divider-line {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #dad9d9;
}

.divider-text {
  white-space: nowrap;
  padding: 0 16px;
}

.divider-line-small {
  width: 16px;
  min-width: 3%;
  border: none;
  border-top: 1px solid #dad9d9;
}
