.ui-tooltip-container {
  height: fit-content;
}

.ui-tooltip-children-wrapper {
  height: fit-content;
  /* width: fit-content; */
  padding: 1px;
}

.ui-tooltip-title {
  position: absolute;
  z-index: 9999;
  border-radius: 5px;
  white-space: wrap;
  background-color: var(--tooltip-bg-color);
  color: var(--white);
  font-size: 14px;
  animation-duration: 0.2s;
}

/* arrows */
.ui-tooltip-title-top::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  border-width: 8px;
  border-style: solid;
  border-color: var(--tooltip-bg-color) transparent transparent transparent;
}

.ui-tooltip-title-bottom::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent var(--tooltip-bg-color) transparent;
}

.ui-tooltip-title-right::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  border-width: 8px;
  border-style: solid;
  border-color: transparent var(--tooltip-bg-color) transparent transparent;
}

.ui-tooltip-title-left::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent var(--tooltip-bg-color);
}

/* arrows level 2 combined */
.ui-tooltip-title-top-right::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 10px; /* Adjust this to center the arrow */
  border-width: 4px;
  border-style: solid;
  border-color: var(--tooltip-bg-color) transparent transparent transparent;
}
.ui-tooltip-title-top-left::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 10px; /* Adjust this to center the arrow */
  border-width: 4px;
  border-style: solid;
  border-color: var(--tooltip-bg-color) transparent transparent transparent;
}
