/* Base styles */
.info-box {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  color: #333;
}

/* Type-specific styles */
.info-box-info {
  background-color: #eff6ff;
  border-left: 4px solid #b1f8f0;
  border-right: 4px solid #b1f8f0;
}

.info-box-warning {
  background-color: #fff3e0;
  border-left: 4px solid #ff9800;
  border-right: 4px solid #ff9800;
}

.info-box-error {
  background-color: #ffebee;
  border-left: 4px solid #d32f2f;
  border-right: 4px solid #d32f2f;
}

.info-box-success {
  background-color: #e8f5e9;
  border-left: 4px solid #388e3c;
  border-right: 4px solid #388e3c;
}
.info-box-gray {
  background-color: #f0f0f0;
  border-right: 4px solid #d1cece;
  border-left: 4px solid #d1cece;
}

/* Language-specific alignment */
.info-box-hebrew,
.info-box-arabic {
  text-align: right;
  border-left: none;
}

.info-box-english,
.info-box-russian {
  text-align: left;
  border-right: none;
}

.info-box-no-border {
  border-color: transparent;
}
