.chart_card_body-chart {
  width: 300px;
  height: 120px;
  /* border: 1px solid red; */
  display: flex;
  gap: 15px;
  align-items: center;
}

.chart-card-recommended-store-tooltip-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
}
