.legal_terms-container {
  max-width: 800px;
  margin: 30px auto;
  background-color: var(--bg-secondary-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  padding: 30px;
  line-height: 1.6;
  max-height: 70vh;
  overflow: auto;
}

.legal_terms-title {
  font-size: var(--font-size-xl);
  font-weight: bold;
  color: var(--primary-color); /* Your primary color */
  margin-bottom: 10px;
}

.legal_terms-updated {
  font-size: var(--font-size-small);
  color: var(--font-color);
  margin-bottom: 20px;
}

.legal_terms-section {
  margin-bottom: 20px;
}

.legal_section-title {
  font-weight: 600;
  font-size: var(--font-size-large);
  color: var(--secondary-color); /* Your secondary color */
  margin-bottom: 8px;
}

.legal_section-content {
  color: var(--font-color);
  font-size: var(--font-size-base);
  margin: 0;
}
