header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: var(--white);
  width: 100vw;
  padding: var(--padding-screen);
}

.site_nav_container {
  width: 100%;
  display: flex;
  height: 65px;
  align-items: center;
  justify-content: space-between;
}

.site_nav_box {
  margin: auto 15px;
  /* border: 1px solid red; */
}

.site_nav_box-primary {
  flex: 3;
  margin: auto 5%;
}

/* https://dribbble.com/shots/4735749-Data-Visualization-Color-Palette */

.site_nav_box.hamburger-open {
  display: block;
  flex: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.anim-fade-in {
  animation: fadeIn 0.5s ease-in;
}

.site_nav_hamburger {
  /* flex: 1; */
  margin: auto;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
}

.site-nav_main-logo-wrapper {
  color: var(--primary-color);
}

@media (max-width: 576px) {
  header {
    background-color: var(--primary-color);
  }

  .hamburger-close {
    display: none;
  }

  .site-nav_main-logo-wrapper {
    color: var(--white);
  }
}

/* good guide */
/* https://www.youtube.com/watch?v=kvlSep7m7Uk&ab_channel=FelixYu */
