.description-wrapper,
.description {
  margin: auto;
  margin-bottom: 10px;
  /* width: 100%; */
}
.description {
  padding: 5px 15px;
  display: flex;
  flex-wrap: wrap;
}

.title {
  margin-bottom: 10px;
  text-align: center;
}

.description-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
  font-size: var(--font-size-base);
}

.full-row {
  width: 100%;
}

.complete-row {
  flex-grow: 1;
  width: fit-content;
}

.label {
  padding: 10px;
  font-weight: bold;
  flex-shrink: 0;
  width: 120px;
}

.content {
  text-align: center;
  padding: 10px;
  min-width: 100px;
  padding-left: 10px;
  width: 100% !important;
}

@media screen and (max-width: 576px) {
  .description-item {
    margin-bottom: 0px;
  }
}
