.carousel_container {
  overflow: hidden;
  width: 100%;
  position: relative;
  direction: ltr;
}

.carousel_content_wrapper {
  display: flex;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-bottom: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: var(--light-color);
  transition: all ease 0.3s;
  cursor: pointer;
  border: none;
}

.carousel-btn:active {
  font-size: smaller;
}

.carousel-btn_not_visible {
  opacity: 0;
}

.carousel_container:hover .carousel-btn {
  opacity: 1;
  display: flex;
}

.carousel-btn:hover > div {
  transform: scale(0.9);
  opacity: 0.5;
}

.carousel-next {
  right: 3px;
}
.carousel-prev {
  left: 3px;
}

.carousel_current_none_active {
  transform: translateY(15%);
}
.carousel_current_active {
  transition: transform 0.7s ease;
  perspective: 100px;
}

.carousel-btn_disabled {
  opacity: 0.5 !important;
  cursor: no-drop;
}
