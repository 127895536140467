.department_nav_container {
  /* box-shadow: var(--box-shadow); */
  width: 100%;
  margin-top: 20px;
  color: var(--primary-color);
  font-weight: 600;
  font-size: calc(var(--font-size-small) + 1px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  box-shadow: 0px 8px 10px -15px var(--font-color);
  border-bottom: 2px solid var(--border-color);
}

.nav-department-wrapper {
  flex: 1;
  min-width: fit-content;
  padding: 0px 4px;
  padding-bottom: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  text-overflow: ellipsis;
}

.nav-active-department {
  color: var(--primary-contrast-color);
}

.dd-categories-list {
  display: none;
  position: absolute;
  top: calc(100% - 5px);
  min-width: 200px;
  color: var(--font-color);
  box-shadow: var(--box-shadow-secondary);

  border-radius: 5px;
  z-index: 999;
  animation: dd-pops2 ease-in-out 0.2s;
}

@media screen and (max-width: 1400px) {
  .nav-department-wrapper {
    padding-bottom: 0px;
  }
  .dd-categories-list {
    top: calc(100% - 20px);
  }
}

@keyframes dd-pops2 {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
  }
}

.dd-categories-list > a > li {
  padding: 10px;
  margin: 3px;
  cursor: pointer;
  color: var(--font-color);
  border-radius: 5px;
  transition: all ease-in-out 100ms;
}

.dd-categories-list > a:hover > li {
  background-color: var(--bg-secondary-color);
}

.nav-department-wrapper:hover .dd-categories-list {
  display: block;
  list-style-type: none;
  background-color: var(--white);
  color: var(--font-color);
}

.theme-dark .nav-department-wrapper:hover .dd-categories-list {
  background-color: var(--bg-primary-color);
}
