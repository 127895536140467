.tour-overlay {
  position: fixed;
  z-index: 998; /*level 2*/
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: auto; /* Allow clicks to pass through the overlay */
  /* clip-path: inset(0 0 0 20px); */
}

.tour-tooltip-top::after {
  content: "";
  border-color: var(--white) transparent transparent transparent !important;
}
.tour-tooltip-bottom::after {
  content: "";
  border-color: transparent transparent var(--white) transparent !important;
}
.tour-tooltip-right::after {
  content: "";
  border-color: transparent var(--white) transparent transparent !important;
}

.tour-tooltip-wrapper {
  pointer-events: auto; /* Ensure elements with `data-tour` can be clicked */
  animation: pulse 1.3s infinite;
}

.tour-content-wrapper {
  max-width: 350px;
  text-align: center;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

/* Tooltip bottom */
.tour-tooltip-current-step {
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 28px;
  height: 28px;
  background-color: #5cb7b7;
  color: white;
  font-weight: lighter;
  font-size: 14px;
  box-shadow: var(--box-shadow-secondary);
}

.tour-tooltip-wrapper .dot {
  width: 10px;
  height: 10px;
  border: 1px solid #5cb7b7;
  background-color: var(--white);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  transition: var(--transition);
}
.tour-tooltip-wrapper .tour-arrow {
  cursor: pointer;
  background-color: #5cb7b7;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.tour-tooltip-wrapper .tour-arrow:hover {
  opacity: 0.8;
  font-size: 15px;
}

.tour-tooltip-wrapper .dot:hover,
.tour-tooltip-wrapper .dot-active {
  background-color: #5cb7b7;
  cursor: pointer;
}

.tour-tooltip-wrapper .dot::after {
  content: var(--label-text);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0); /* Start at the same position as the dot */
  opacity: 0; /* Initially hidden */
  color: #5cb7b7;
  font-size: var(--font-size-small);
  transition: var(--transition); /* Smooth transition for both transform and opacity */
}

.tour-tooltip-wrapper .dot:hover::after {
  transform: translate(-50%, -20px); /* Move up smoothly */
  opacity: 1; /* Fade in */
}

.tour-tooltip-wrapper .dot:disabled:not(.dot-active) {
  cursor: not-allowed;
  opacity: 0.5; /* Make it look disabled */
  background-color: #ccc; /* Optional, a grayed-out color */
}
