#pop-confirm-window {
  padding: 2%;
  position: fixed;
  z-index: 100;
  background-color: var(--white);
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 16px;
}

.pop-confirm-top {
  transform: translate(-50%, -50%);
}

.pop-confirm-bottom {
  transform: translate(-50%, 0%);
}
.pop-confirm-right {
  transform: translate(0%, -25%);
}
.pop-confirm-left {
  transform: translate(0%, -25%);
}

/* old */

.pop-confirm-content-show {
  animation: pop-confirm ease-in-out 0.2s;
}

@keyframes pop-confirm {
  0% {
    opacity: 0;
    /* transform: translateY(-10px); */
  }
  100% {
    opacity: 1;
    /* transform: translateY(0); */
  }
}
