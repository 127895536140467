.accessability-float-btn {
  position: fixed;
  top: calc(var(--nav-height) + 90px);
  right: -20px;
  z-index: 9999;
  border-radius: 50%;
}

.accessability-content-container {
  padding: 10px;
  height: 99%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3%;
}

@media screen and (max-width: 576px) {
  .accessability-float-btn {
    top: 70px;
  }
}

/* accessability-action-box */
.accessability-action-box {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  width: 33%;
  transition: var(--transition);
  color: var(--font-color);
}

.accessability-action-box span:first-child {
  padding: 8px;
  background-color: var(--primary-color);
  border-radius: 50%;
}

.accessability-action-box:hover {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
}

/* contrast icon */
.contrast_circle_icon {
  width: 25px;
  height: 25px;
  border-radius: 50%; /* Makes it a circle */
  border: 2px solid var(--black); /* Adds a black border */
  background: linear-gradient(
    to right,
    var(--black) 50%,
    var(--white) 50%
  ); /* Divides the circle into black and white halves */
}

.font_icon {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; /* Add font-family here */
  font-size: 25px;
  font-weight: 550;
  color: var(--black);
}

.font_icon span:last-child {
  font-size: 15px;
}
