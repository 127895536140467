.product_card_wrapper_vvv {
  margin: 10px;
  min-height: 270px;
  max-height: 290px;
  max-width: 310px;
  border-radius: 5px;
  box-shadow: var(--box-shadow-secondary);
  color: var(--font-color-secondary);
  text-align: center;
  position: relative;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: box-shadow 0.3s ease;
  overflow: hidden;
  align-content: center;
}

@media screen and (max-width: 450px) {
  .product_card_wrapper_vvv {
    width: 95vw;
    max-width: none;
    margin: 10px auto;
    border-radius: 0;
  }
}

.product_data_wrapper {
  font-size: var(--font-size-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid red; */
  height: 100%;
  flex: 1;
  width: 100%;
}
.product_data_wrapper .item_name_card {
  color: var(--font-color-dark);
  /* color: var(--black); */
  font-weight: bolder;
  width: 100%;
  padding: 0 10%;
  padding-bottom: 8px;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  display: block;
  height: 55px;
}

.product_data_wrapper .item_img_card {
  max-width: 250px;
  /* border: 1px solid red; */
  width: 100%;
  flex: 1;
  /* position: relative; */
  /* z-index: 1; */
}

.productPrice {
  font-weight: bold;
  color: var(--font-color-secondary);
  font-size: calc(var(--font-size-base) - 1px);
}

.product_card_bottom {
  /* font-weight: 550; */
  margin-bottom: 10px;
}

.add_cart_btn {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%);
  align-items: center;
  justify-content: space-around;
  display: flex;
  visibility: hidden;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  width: 50% !important;
  opacity: 0; /* Start fully transparent */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition */
}
.store_name_card {
  color: transparent;
  min-height: 30px !important;
}
.product_card_wrapper_vvv:hover {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.product_card_wrapper_vvv:hover .store_name_card {
  color: var(--primary-color);
}

.product_card_wrapper_vvv:hover .add_cart_btn,
.product_card_wrapper_vvv:focus .add_cart_btn,
.item_img_card:focus .add_cart_btn,
.add_cart_btn:focus {
  /* width: 5%; */
  z-index: 999;
  opacity: 0.9; /* Fully visible */
  visibility: visible;
}
