#hiw-container,
.hiw-icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#hiw-cards-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  position: relative;
  padding-top: 20px;
}

.hiw-card-wrapper {
  gap: 10px;
  max-width: 250px;
  /* width: 185px; */
}

.hiw-card-wrapper p {
  color: var(--font-color-secondary);
  font-size: 20px;
  text-align: center;
  padding: 5px;
}

.hiw-icon-wrapper {
  background-color: var(--white);
  width: 100%;
  height: 230px;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  flex-shrink: 0;
}

.hiw-wave-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 60%;
  /* transform: scaleX(-1); */
}

@media screen and (max-width: 1300px) {
  #hiw-container {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .hiw-card-wrapper {
    max-width: 160px;
  }
}

@media screen and (max-width: 700px) {
  #hiw-cards-wrapper {
    flex-direction: column;
    gap: var(--spacing-xl);
    align-items: center;
  }

  .hiw-card-wrapper {
    width: 80vw;
    max-width: none;
  }
}
