.product-amount-wrapper {
  height: 50px;
  position: absolute;
  /* top: 45%; */
  top: 10px;
  height: 65%;
  left: 10%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15%;
  opacity: 0.75;
  gap: 5%;
  /* border: 1px solid red; */
  background-color: var(--light-color);
}

.product-amount-wrapper_info {
  position: absolute;
  top: 80%;
}

.amount {
  /* border: 1px solid var(--primary-color); */
  font-size: 2.5rem;
  padding: 1rem 1.5rem;
  background-color: var(--light-color);
  color: var(--primary-color);
  border-radius: 10px;
  font-weight: bold;
}

.amount-changer:hover {
  cursor: pointer;
  transform: scale(1.2);
}
