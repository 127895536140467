.site_nav_option_title {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .site_nav_option_title {
    flex-direction: column;
  }
}
