@import "./padding.css";

.hover:hover {
  transform: rotate(90deg);
  transition: 0.2s ease-in-out;
}

.clickable-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.clickable-icon:hover {
  cursor: pointer;
  opacity: 0.8;
  transform: scale(0.9);
}

.clickable-icon:active {
  opacity: 1;
  transform: scale(0.8);
}

:root {
  /* Colors */
  /* --primary-color: #064c4f; */
  --primary-color: #0e3f02;
  --primary-contrast-color: #faa817;
  --secondary-color: #064c4f;
  --shiny-color: #bbea70;
  --light-p-color: #e2e5dd;
  --light-p2-color: #f5f1dc;
  --img-mix-theme: white;

  /* theme colors */
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --white: white;
  --black: black;
  --font-color-dark: #333;
  --font-color: #555;
  --font-color-secondary: #878787;
  --border-color: #d9d9d9ff;
  --line: 0505050F;

  /* Background Colors */
  --bg-primary-color: #f4f4f9;
  --bg-secondary-color: #e9e9e9;
  --bg-weak-color: #f9fafb;
  /* --bg-secondary-color: #e9ecef;  */

  /* TODO handle this */
  /* --focus-color: #00ff00; */

  /* icons */
  --iconA: #bbdfbb00;
  --iconB: #c6e4c671;
  --iconBC: #a7d3a7be;
  --iconC: var(--primary-color);

  /* Typography */
  --font-family: "Roboto", sans-serif;
  --font-size-small: 12px;
  --font-size-base: 16px;
  --font-size-large: 20px;
  --font-size-xl: 24px;
  --line-height-base: 1.5;

  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 48px;

  /* Borders */
  --border-radius: 4px;
  --border-width: 1px;
  --border-color: #dee2e6;
  --border: 1px var(--border-color) solid;

  /* Shadows */
  --box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  --box-shadow-secondary: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  /* utils */
  --nav-height: 120px;
  --footer-sm-height: 30px;
  --transition: all 0.3s ease-in-out;
  --padding-screen: 0 5%;
}

/* Dark theme */
.theme-dark {
  --primary-color: #1e8449;
  --secondary-color: #b3e8d8;
  --img-mix-theme: #686868;
  --light-color: #343a40;
  --light-secondary-color: #3f4041;
  --dark-color: #f8f9fa;
  --white: black;
  --black: white;
  --font-color: #ffffffd9;
  --font-dark: #ffffffec;
  --font-color-secondary: #ffffff9a;
  --bg-primary-color: #181414;
  --bg-secondary-color: #252020;
  --border-color: rgb(0, 0, 0);
  --line: #fdfdfd1f;
  --box-shadow: 0 2px 4px rgba(150, 150, 150, 0.1);
  --box-shadow-secondary: rgba(114, 114, 114, 0.322) 0px 3px 8px;
  --focus-color: yellow;
  --bg-weak-color: #2e2b2b;
}

/* High-Contrast Mode */
/* High-Contrast Mode */
.theme-high-contrast {
  --primary-color: #000000; /* Black for text and primary content */
  --secondary-color: #030303; /* White for secondary content like backgrounds */
  --font-color: #000000;

  /* Background Colors */
  --bg-primary-color: #ffffff; /* Black background for highest contrast */
  --bg-secondary-color: #f0f0f0; /* Slightly lighter for secondary sections */

  /* Borders and Dividers */
  --border-color: #000000; /* White borders for strong visibility */
  --line: #000000; /* Ensure lines and dividers have high contrast */

  /* Focus and Hover States */
  --focus-color: #00ff00; /* Bright yellow focus indicator for interactive elements */

  /* Shadows */
  --box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px; /* Stronger shadow for clarity */
  --box-shadow-secondary: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  /* Button and Link Colors */
  --button-bg-color: #000000; /* Black buttons for high contrast */
  --button-text-color: #ffffff; /* White text for button visibility */
  --border-color: rgb(99, 99, 99);

  /* Input Fields */
  --input-bg-color: #ffffff; /* White input field background */
  --input-text-color: #000000; /* Black text for clarity */
  --input-border-color: #000000; /* Strong input borders for visibility */

  /* Icons */
  --iconA: #000000; /* Black icons for high contrast */
  --iconB: #363636;
  --iconBC: #363636;
  --iconC: #444444; /* Ensure icons are easily visible */

  /* Typography */
  --font-color-dark: #000000; /* Black font for dark text on light backgrounds */
  --font-color-secondary: #333333; /* Slightly lighter secondary text for readability */
}

@media (max-width: 1800px) {
  :root {
    --padding-screen: 0 3%;
  }
}
@media (max-width: 576px) {
  :root {
    --padding-screen: 0 1%;
  }
}

.theme-high-contrast button:focus,
.theme-dark button:focus,
a:focus,
input:focus,
select:focus,
textarea:focus {
  outline: 3px solid var(--focus-color); /* More intense focus indicator */
  outline-offset: 2px;
}

.theme-high-contrast img {
  filter: brightness(90%) contrast(140%);
}

/* Accessability - Typography */
.theme-font-large {
  --font-size-small: 14px;
  --font-size-base: 18px;
  --font-size-large: 22px;
  --font-size-xl: 26px;
}

.theme-font-xl {
  --font-size-small: 18px;
  --font-size-base: 22px;
  --font-size-large: 26px;
  --font-size-xl: 30px;
}

.theme-show_link_underline a {
  text-decoration: underline;
}

.theme-disable-animations * {
  animation: none !important;
  transition: none !important;
}

/* word-spacing: default 0.25em*/
.theme-word-spacing-xl {
  word-spacing: 0.65em;
}
.theme-word-spacing-large {
  word-spacing: 0.4em;
}

.curser-help {
  cursor: help;
}
