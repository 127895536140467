.login-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  /* height: 100vh; */
  max-width: 1200px;
  margin: 10% auto;
  background-color: var(--background-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.signup-link {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

.signup-link a {
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: 600;
}

/* Login page 2 */

.modern-container {
  position: relative;
  flex: 1;
  max-width: 600px;
  height: 70%;
  background-color: var(--secondary-color);
  background-image: linear-gradient(var(--secondary-color), #0b6669);
  color: var(--white);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  gap: 30;
}

/* Top Left Quarter Circle */
.modern-container::before {
  content: "";
  position: absolute;
  top: -150px;
  left: -150px;
  width: 300px;
  height: 300px;
  background-image: radial-gradient(var(--secondary-color), var(--secondary-color), var(--border-color));
  border-radius: 50%;
}

/* Bottom Right Quarter Circle */
.modern-container::after {
  content: "";
  position: absolute;
  z-index: 0;
  right: -75px;
  bottom: -75px;
  width: 150px;
  height: 150px;
  background-image: radial-gradient(var(--secondary-color), var(--secondary-color), var(--border-color));
  border-radius: 50%;
}

.content {
  position: relative;
  z-index: 1;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content h1 {
  font-size: 36px;
  font-weight: 400;
}

.content p {
  color: var(--font-color-secondary);
}

.content-img {
  width: 80%;
  height: 300px;
  /* background-color: var(--bg-primary-color); */
  /* border-radius: 15px; */
}
