#product-filter_float-btn {
  padding: 8px;
}

#filter-container {
  width: 100%;
  max-width: 390px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: auto;
}

#filter-items-wrapper {
  height: 95%;
  overflow-y: auto;
  position: relative;
}

/* #filter-toggle-btn { */
#filter-btns-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20px;
  box-shadow: var(--box-shadow);
  color: var(--primary-color);
  font-weight: bolder;
  transition: var(--transition);
  position: relative;
}

/* #filter-toggle-btn:hover {
  background-color: var(--bg-secondary-color);
  opacity: 0.8;
} */

.filters-search-ui-input {
  border: none;
  font-size: 20px;
}

.filters-search-ui-input:focus {
  border: none;
  outline: none;
}

.filter-reset-filters-btn:hover {
  opacity: 0.8;
  cursor: pointer;
}
