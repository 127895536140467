#hp-footer {
  background-color: var(--primary-color);
  color: var(--white);
  direction: ltr;
  padding: var(--spacing-lg) 0;
  width: 100vw;
}

.hp-footer-container {
  display: flex;
  flex-direction: column;
}

.hp-footer-section {
  padding: var(--padding-screen);
  display: flex;
  justify-content: space-between;
}

.hp-main-footer-card {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  gap: 10px;
}

.hover-contrast {
  cursor: pointer;
  display: flex;
}

.hover-contrast:hover {
  color: var(--primary-contrast-color);
}

.hp-footer-bottom {
  margin-top: 20px;
  border-top: var(--font-color-secondary) 1px solid;
  padding-top: 10px;
}

.hp-footer-icon-wrapper {
  border: 3px solid var(--primary-contrast-color);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  color: var(--primary-contrast-color);
  cursor: pointer;
  transition: var(--transition);
}

.hp-footer-icon-wrapper:hover {
  border-color: var(--primary-contrast-color);
  background-color: var(--primary-contrast-color);
  color: var(--primary-color);
}

@media screen and (max-width: 576px) {
  .hp-footer-section {
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
  }
  .hp-main-footer-card {
    max-width: none;
    width: 65%;
  }
}
