#promotion-card-main {
  width: 75px;
  color: var(--white);
  text-align: center;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  top: 0px;
  left: 0;
  transition: ease 0.5s all;
  padding: 5px 5px 10px 5px;
  overflow: auto;
  height: 2rem;
  background-color: var(--secondary-color);
  border-radius: 5px 0;
  z-index: 10;
}

#promotion-card-main:hover {
  min-height: 5rem;
  height: 90%;
  width: 100%;
  border-radius: inherit;
  opacity: 0.9;
  gap: 5%;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.5rem;
  overflow-y: auto;
  font-weight: 550;
  background-color: var(--primary-color);
  z-index: 9999;
}

.promotion-context {
  margin: 5px auto;
  padding: 5px;
  opacity: 1;
  width: 100%;
}

.promotion-context.bottom_line {
  border-bottom: 1px solid rgba(221, 221, 221, 0.74);
}

.promotion-title {
  display: flex;
  align-items: center;
}

.promotion-context > div:not(.promotion-title) {
  padding: 5px 10px;
}
