#hp-cover-wrapper {
  gap: var(--spacing-xxl);
  width: 100%;
  flex-wrap: wrap;
}

#hp-cover-right-static,
#hp-cover-left-carousel {
  height: 350px;
  border-radius: 10px;
  min-width: 300px;
  min-height: 300px;
  max-width: 100%;
  overflow: hidden;
  box-shadow: var(--box-shadow);
}
#hp-cover-left-carousel {
  flex: 1.5;
  border-radius: 20px;
  padding: 2px;
  overflow: hidden;
}

#hp-cover-right-static {
  padding: var(--spacing-xl) var(--spacing-xl);
  background-color: var(--bg-secondary-color);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* border: 1px solid red; */
  gap: 5%;
  line-height: var(--spacing-xl);
}

.hp-cover-btn {
  width: fit-content;
}

@media screen and (max-width: 576px) {
  #hp-cover-wrapper {
    flex-direction: column;
    width: 90%;
    margin: auto;
  }

  #hp-cover-right-static {
    height: fit-content;
  }
}
