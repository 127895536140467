.dd-box {
  cursor: grab;
  padding: 5px 7px;
  border: 1px solid var(--primary-contrast-color);
  color: var(--primary-color);
  width: 100px;
  text-align: center;
  border-radius: 5px;
  transition: var(--transition);
}

.dd-box:hover {
  border: 1px solid var(--primary-color);
  color: var(--primary-contrast-color);
}
