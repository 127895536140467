.segmented-controls {
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 0.5rem;
  background: var(--border-color);
  /* background-color: red; */
  height: 2.5rem;
  padding: 3px;
  direction: ltr;
  color: var(--font-color);
}

.segmented-controls input {
  position: absolute !important;
  height: 1px;
  width: 1;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  text-align: center;
}

.segmented-controls label:last-of-type::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  background-color: var(--light-color);
  transition: inherit;
}

.segmented-controls label {
  width: 100px;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  flex: 1 1 0px;
  position: relative;
  z-index: 2;
  transition: inherit;
  font-size: var(--font-size-small);
  font-weight: 500;
  line-height: 1.5rem;
}

.segmented-controls label:last-of-type {
  z-index: 1;
}

.segmented-controls input:checked + label {
  font-weight: bold;
}

.segmented-controls input:checked + label::before,
.segmented-controls input:checked + label + input + label::before {
  opacity: 1;
}

.segmented-controls label:last-of-type::after {
  background-color: var(--light-color);
  border-radius: 0.4275rem;
}
.segmented-controls label:hover {
  background: var(--bg-primary-color);
  border-radius: 10px;
}

.segmented-controls label:active {
  background-color: var(--light-secondary-color); /* Change to desired hover background color */
  transition: all 300ms cubic-bezier(0.694, 0, 0.335, 1), background-color 100ms cubic-bezier(0.694, 0, 0.335, 1),
    color 100ms cubic-bezier(0.694, 0, 0.335, 1);
}
