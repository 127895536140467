.form-input-group {
  align-self: center;
  /* width: clamp(240px, 100%, 320px); */
  position: relative;
  height: 3.5rem;
  margin: 3px auto;
}

.form__input {
  width: 100%;
  border: 1px solid var(--border-color);
  transition: all 250ms ease;
}

.form__input_sizing {
  font-size: var(--font-size-base);
  border-radius: 8px;
  margin: 8px auto;
  padding: 12px;
}

.theme-dark .form__input {
  color: var(--font-color);
  background-color: var(--light-color);
}

.form__input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px var(--light-color) inset !important;
  box-shadow: 0 0 0px 1000px var(--light-color) inset !important;
  background-color: var(--light-color) !important;
  border: 1px solid var(--secondary-color) !important;
  color: var(--font-color) !important;
  font-size: var(--font-size-base);
}

.input-field {
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.form__input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 193, 99, 0.2);
  border-color: var(--primary-contrast-color);
}

.form__input:focus + .form__label,
.form__input:not(:placeholder-shown) + .form__label {
  transform: translate(-0.1rem, -5rem) scale(1);
  margin-bottom: 8px;
  color: var(--primary-color);
  font-weight: 500;
}

.form__input::placeholder {
  display: none;
  color: transparent;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* When the input has a specific class for placeholder visibility */
.form__input.form_input_placeholder_include::placeholder {
  display: block !important;
  color: var(--primary-color) !important;
  opacity: 0.8;
}

.form__label {
  font-size: 1rem;
  display: block;
  transform: translate(1rem, -2.8rem);
  transform-origin: 0 0;
  transition: all 500ms;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  pointer-events: none;
  color: var(--font-color);
}

.form__icon {
  top: 50%;
  transform: translateY(-40%);
  position: absolute;
  right: 1rem;
  font-size: 1.1em;
  color: var(--border-color); /* Default color with fallback */
  /* cursor: pointer; */
}

.form__message {
  line-height: 1rem;
  font-size: var(--font-size-small);
  transform: translate(-0.5rem, 0);
  margin: auto;
  /* width: clamp(240px, 100%, 320px); */
}

.rtl .form__label {
  transform: translate(-1.25rem, -2.5rem);
}

.rtl .form__icon {
  left: 1rem;
  right: auto;
}
